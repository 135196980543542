import React from "react";
import "./Modal.css";

function Modal({setOpenModal, title, text}) {
    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="title">
                    <h2>{title}</h2>
                </div>
                <div className="body">
                    <p dangerouslySetInnerHTML={{__html: text}}></p>
                </div>
            </div>
        </div>
    );
}

export default Modal;