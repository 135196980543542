import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import './Footer.css';
import {scrollUP} from '../../Shared/ScrollTop/ScrollTop';
import {useTranslation} from "react-i18next";
import Modal from "../../Shared/Modal/Modal";


const Footer = () => {

    const [openModalPrivacyPolicy, setOpenModalPrivacyPolicy] = useState(false);
    const [openModalTermsAndConditions, setOpenModalTermsAndConditions] = useState(false);

    function getYear() {
        return new Date().getFullYear();
    }

    const {t} = useTranslation();

    return (
        <section className='row footer'>
            {openModalPrivacyPolicy &&
                <Modal setOpenModal={setOpenModalPrivacyPolicy} title={t('footer.otherLinks.privacyPolicy')}
                       text={t('footer.otherLinks.privacyText') + t('contactUs.contactInfo.email') + "."}/>}
            {openModalTermsAndConditions && <Modal setOpenModal={setOpenModalTermsAndConditions}
                                                   title={t('footer.otherLinks.termsConditions')}
                                                   text={t('footer.otherLinks.termsText') + t('contactUs.contactInfo.email') + "."}/>}
            <Row className="col-md-11 mx-auto">
                <Col md={6} lg={3} className="fAboutUs">
                    <h5>{t('footer.about')}</h5>
                    <span className="animate-border"></span>
                    <p className="aboutUsDes">{t('footer.aboutDescription')}</p>
                    <ul className="socialIcons">
                        <li>
                            <a href="https://linkedin.com/company/web2-web3/">
                                <FontAwesomeIcon icon={faLinkedinIn}/>
                            </a>
                        </li>
                    </ul>
                </Col>
                <Col md={6} lg={3} className="footerLink">
                    <h5>{t('footer.usefulLinks.title')}</h5>
                    <a href="/" onClick={scrollUP}>
                        <li>{t('footer.usefulLinks.home')}</li>
                    </a>
                    <a href="#about">
                        <li>{t('footer.usefulLinks.about')}</li>
                    </a>
                    <a href="#services">
                        <li>{t('footer.usefulLinks.services')}</li>
                    </a>
                    <a href="#technologies">
                        <li>{t('footer.usefulLinks.technologies')}</li>
                    </a>
                    <a href="#contact">
                        <li>{t('footer.usefulLinks.contact')}</li>
                    </a>
                </Col>
                <Col md={6} lg={3} className="footerLink">
                    <h5>{t('footer.services.title')}</h5>
                    <a href="#services">
                        <li>{t('footer.services.service1')}</li>
                    </a>
                    <a href="#services">
                        <li>{t('footer.services.service2')}</li>
                    </a>
                    <a href="#services">
                        <li>{t('footer.services.service3')}</li>
                    </a>
                    <a href="#services">
                        <li>{t('footer.services.service4')}</li>
                    </a>
                    <a href="#services">
                        <li>{t('footer.services.service5')}</li>
                    </a>
                    <a href="#services">
                        <li>{t('footer.services.service6')}</li>
                    </a>
                </Col>
                <Col md={6} lg={3} className="footerLink">
                    <h5>{t('footer.otherLinks.title')}</h5>
                    <a style={{cursor: "pointer"}} onClick={() => {
                        setOpenModalPrivacyPolicy(true);
                    }}>
                        <li>{t('footer.otherLinks.privacyPolicy')}</li>
                    </a>
                    <a style={{cursor: "pointer"}} onClick={() => {
                        setOpenModalTermsAndConditions(true);
                    }}>
                        <li>{t('footer.otherLinks.termsConditions')}</li>
                    </a>
                    <a href="#contact">
                        <li>{t('footer.otherLinks.support')}</li>
                    </a>
                    <a href="https://www.womaglobal.com/">
                        <li>{t('footer.otherLinks.partner1')}</li>
                    </a>
                </Col>
            </Row>
            <p className="copyRight">{t('footer.copyright')} {getYear()}&nbsp;&nbsp;&nbsp;9494-0244 QUEBEC
                INC. (Web2 Web3)&nbsp;&nbsp;&nbsp;{t('footer.allRightsReserved')}</p>
        </section>
    );
};

export default Footer;