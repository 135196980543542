import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import './Navbar.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHive} from '@fortawesome/free-brands-svg-icons';
import {Container, Dropdown, DropdownButton, Nav, Navbar} from 'react-bootstrap';
import {useAppContext} from '../../../context';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";


const NavBar = () => {
    const {state: {user}} = useAppContext()
    console.log(user)
    const [isSticky, setSticky] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setSticky(true)
            } else {
                setSticky(false)
            }
        })
    }, [])

    const {t} = useTranslation();

    function changeLanguage(lang) {
        i18n.changeLanguage(lang);
    }

    const scrollTop = () => window['scrollTo']({top: 0, behavior: 'smooth'});
    return (
        <Navbar className={`navbar navbar-expand-lg navbar-light ${isSticky ? "navStyle" : "navDefault"}`} expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={scrollTop} className="navBrn">
                    <FontAwesomeIcon icon={faHive} style={{marginRight: "7px"}}
                                     className="brnIcon"/>{t("navigation.name1")}&nbsp;<span
                    className="navHighlight">{t("navigation.name2")}</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto mainNav" activeKey="/home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" className="nav-link"
                                      onClick={() => window['scrollTo']({
                                          top: 0,
                                          behavior: 'smooth'
                                      })}>{t("navigation.home")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#about" className="nav-link">{t("navigation.about")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#services" className="nav-link">{t("navigation.services")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#contact" className="nav-link">{t("navigation.contact")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{display: "flex", paddingLeft: "16px", paddingRight: "16px"}}>
                            <DropdownButton id="dropdown-basic-button"
                                            style={{margin: "auto", display: "flex"}}
                                            title={<FontAwesomeIcon icon={faGlobe}
                                                                    style={{fontSize: "15px", display: "flex"}}/>}>
                                <Dropdown.Item onClick={() => changeLanguage('en')}>{t("en")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage('fr')}>{t("fr")}</Dropdown.Item>
                            </DropdownButton>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;