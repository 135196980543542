import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter as Router} from "react-router-dom";
import {AppProvider} from "./context";
import '../src/locales/i18n';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <AppProvider>
                <App/>
            </AppProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
