import React from 'react';
import Service from './Service';
import {useTranslation} from "react-i18next";

const Services = () => {
    const {t} = useTranslation();
    return (
        <section id="services" className="services">
            <h4 className="miniTitle text-center">{t("services.title")}</h4>
            <div className="text-center">
                <h5 className="text-center sectionTitle">
                    {t("services.subTitle1")}<br/>{t("services.subTitle2")}
                </h5>
            </div>
            <div className="row mt-4 container mx-auto justify-content-center">
                <Service name={t("services.service1.title")} price={t("services.service1.price")}
                         description={t("services.service1.description")}/>
                <Service name={t("services.service2.title")} price={t("services.service2.price")}
                         description={t("services.service2.description")}/>
                <Service name={t("services.service3.title")} price={t("services.service3.price")}
                         description={t("services.service3.description")}/>
                <Service name={t("services.service4.title")} price={t("services.service4.price")}
                         description={t("services.service4.description")}/>
                <Service name={t("services.service5.title")} price={t("services.service5.price")}
                         description={t("services.service5.description")}/>
                <Service name={t("services.service6.title")} price={t("services.service6.price")}
                         description={t("services.service6.description")}/>
            </div>
        </section>
    );
};

export default Services;