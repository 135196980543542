import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import enLang from './lang/en.json';
import frLang from './lang/fr.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain']
        },
        resources: {
            en: {
                translation: enLang
            },
            fr: {
                translation: frLang
            }
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

function normalizeLanguage(lang) {
    return lang.split('-')[0];
}

i18n.changeLanguage(normalizeLanguage(i18n.language));

export default i18n;
