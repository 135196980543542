import {createContext, useContext, useReducer} from "react";
import {reducer} from "./reducer";

const AppContext = createContext();

const initialState = {
    admin: false,
    selectedService: {},
};

export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};
