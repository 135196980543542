import React from 'react';
import './Service.css'
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const Service = ({name, price, description, img}) => {

    const {t} = useTranslation();

    function handleSelectedService() {
        document.getElementById('contact-subject').value = t("contactUs.meeting") + name;
        window.location.href = "#contact";
    }

    return (
        <div className="col-md-6 col-lg-4 service">
            <Fade bottom duration={2700} distance='70px'>
                <div className="service-card">
                    <div className="text-center">
                        <img src={`${img}`} alt="" className="serviceImg"/>
                    </div>
                    <h4 className="serviceName">{name}</h4>
                    <p className="serviceDes">{description}</p>
                    <div className="bookingBox">
                        <p className="servicePrice">{price}</p>
                        <button className="bookingBtn"
                                onClick={handleSelectedService}
                        >{t("services.bookNow")}</button>
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default Service;