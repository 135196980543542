import React, {useRef} from 'react';
import {Col, Row} from 'react-bootstrap';
import './Contact.css';
import contactImg from '../../../Assets/contact.svg';
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import emailjs from '@emailjs/browser';
import toast, {Toaster} from 'react-hot-toast';

const Contact = () => {
    const {t} = useTranslation();
    const formRef = useRef();
    const handleSubmit = event => {
        event.preventDefault();
        try {
            emailjs.sendForm('service_xgbm5dk', 'template_gwq5ic8', formRef.current, 'D8WsqU3AReupJn8Hl')
                .then((result) => {
                    console.log(result.text);
                    toast.success(t("contactUs.emailSuccess"));
                    event.target.reset();
                }, (error) => {
                    console.log(error.text);
                    toast.error(t("contactUs.emailFail") + t("contactUs.contactInfo.email"));
                });
        } catch (error) {
            console.log(error.text);
            toast.error(t("contactUs.emailFail") + t("contactUs.contactInfo.email"));
        }
    }
    return (
        <section id="contact">
            <Col md={11} className="mx-auto">
                <Row>
                    <Col md={6}>
                        <form ref={formRef} onSubmit={handleSubmit} className="contactForm">
                            <h4 className="miniTitle">{t("contactUs.title")}</h4>
                            <h5 className="sectionTitle"
                                style={{marginBottom: "25px"}}>{t("contactUs.subTitle")}</h5>
                            <Typography variant="h6" component="p" color="text.secondary"
                                        style={{marginBottom: "25px"}}>
                                {t("contactUs.description")}
                            </Typography>
                            <Row>
                                <Col md={12} lg={6}>
                                    <input placeholder={t("contactUs.form.name")} name="from_name" type="text"
                                           required/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <input placeholder={t("contactUs.form.email")} name="from_email" type="email"
                                           required/>
                                </Col>
                                <Col md={12}>
                                    <input id="contact-subject" placeholder={t("contactUs.form.subject")}
                                           name="subject" type="text" required/>
                                </Col>
                                <Col md={12}>
                                    <textarea placeholder={t("contactUs.form.message")} name="message"
                                              required></textarea>
                                </Col>
                            </Row>
                            <button className="branBtn" type="submit">{t("contactUs.form.submit")}</button>
                        </form>
                    </Col>
                    <Col md={6}>
                        <img src={`${contactImg}`} alt="" className="img-fluid"/>
                    </Col>
                </Row>
            </Col>
            <Toaster position="bottom-left" toastOptions={{
                success: {
                    style: {
                        background: '#ceeace',
                    },
                },
                error: {
                    style: {
                        background: '#FFCCCB',
                    },
                },
            }}/>
        </section>
    );
};

export default Contact;
