import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {vs2015} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import './Technologies.css'

const Technologies = () => {
    const theme = useTheme();

    const {t} = useTranslation();

    return (
        <section id="technologies">
            <br/>
            <br/>
            <br/>
            <Box>
                <Box marginBottom={4}>
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="text.primary"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            <span className="sub-text">{t("technologies.title")}</span>
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.secondary"
                            sx={{fontWeight: 400}}
                            align={'center'}
                        >
                            <span className="sub-text">{t("technologies.subTitle")}</span>
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" className="tech-list">
                    <Box
                        sx={{width: {xs: '100%', md: '50%'}}}
                        width={50}
                        component={SyntaxHighlighter}
                        language={'javascript'}
                        style={vs2015}
                        padding={`${theme.spacing(2)} !important`}
                        borderRadius={2}
                        margin={`${theme.spacing(0)} !important`}
                        bgcolor={'#21325b !important'}
                    >
                        {t('technologies.list')}
                    </Box>
                </Box>
            </Box>
        </section>
    )
}

export default Technologies
