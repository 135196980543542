import React from 'react';
import teamPic from '../../../Assets/about.svg';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation();

    function goToServices() {
        window.location.href = "#services";
    }

    return (
        <section className="about overflow-hidden py-5" id="about">
            <div className="row w-100">
                <div className="row col-md-11 mx-auto ">
                    <div className="col-md-6 img">
                        <Fade duration={2000} left>
                            <img src={`${teamPic}`} alt="" className="img-fluid"/>
                        </Fade>
                    </div>
                    <div className="col-md-6 ps-2">
                        <Fade duration={2000} right>
                            <p className="miniTitle">{t("aboutUs.title")}</p>
                            <h1 className="headerTitle">{t("aboutUs.headline")}</h1>
                            <p className="headerContent">{t("aboutUs.description")}</p>
                            <button className="branBtn" onClick={goToServices}>{t("aboutUs.learnMore")}</button>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;