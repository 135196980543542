import Home from "../src/component/Home/Home/Home";
import {Route, Routes} from "react-router-dom";
import React, {createContext, useEffect} from "react";
import NotFound from "./component/NotFound";
import {useTranslation} from "react-i18next";

export const UserContext = createContext();

const App = () => {
    const {i18n} = useTranslation();
    useEffect(() => {
        const urlLanguage = window.location.pathname.split('/')[1];
        console.log('Browser languages:', navigator.languages);
        console.log('Browser languages:',i18n.language);
        if (urlLanguage) {
            i18n.changeLanguage(urlLanguage);
        }
    }, []);
    return (
        <div>
            <Routes>
                <Route path="/en" element={<Home/>}/>
                <Route path="/fr" element={<Home/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </div>
    );
};

export default App;
